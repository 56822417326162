import {React,useState,useRef} from 'react'
import '../Scan.css'
import logo from '../imgs/logo.png'
// import inst_video from '../video/inst_video.webm'

function Scan() {
    const [hideVideo,sethideVideo] =  useState(false);
    const [hideSkip,sethideSkip] = useState(false);
    const vidRef = useRef(null);
    const handleStopVideo = ()=>{
        vidRef.current.pause();
    }
  return (
    <div className='scan'>
            <img src={logo} alt="Docsun" className="logo"/>
            <p>version-2.5.221111.1255.syst+chol</p>
            <h2 id="checker">Modules Loading</h2>
            <p>FPS : <span id='fps_msg'></span></p>
            <video hidden id="video" width="640" height="480"> </video>
            <div className='skip__BtnCont'>
                <button id='skip_btn' onClick={()=>{sethideVideo(true);sethideSkip(true);handleStopVideo();}} hidden={hideSkip}>Skip Video</button>
            </div>
            <div className="scan__canvasContainer">
                <div className='progress__container'>
                    <div id="progress__value"></div>
                </div>
                <video ref={vidRef} loop muted autoPlay controls hidden={hideVideo} >
                    <source src="https://docsun-1314646566.cos.ap-shanghai.myqcloud.com/video/inst_video.webm" type="video/webm"></source>
                </video>
                <canvas id = "canvas" width="640" height="480"></canvas>
            </div>
            <div className="scan__vitalBox">
                {/* Basic Module elements */}
                <div className="scan__Box">
                    <label htmlFor='heart_rate'>Heart Rate</label><br/>
                    <input type="text" id="heart_rate"/>
                </div>
                <div className="scan__Box">
                    <label htmlFor='resp_rate'>Respiratory Rate</label><br/>
                    <input type="text" id="resp_rate"/>
                </div>
                <div className="scan__Box">
                    <label htmlFor='spo2'>Spo2 Level</label><br/>
                    <input type="text" id="spo2"/>
                </div>
                
            </div >
            <div className="scan__vitalBox">
                {/* Blood Pressure Module Elements */}
                <div className="scan__Box" >
                    <label htmlFor='blood_pressure'>Blood Pressure</label><br/>
                    <input type="text" id="blood_pressure"/>
                </div>
                {/* Blood Sugar Module Elements */}
                <div className="scan__Box">
                    <label htmlFor='bs_rate'>Blood Sugar</label><br/>
                    <input type="text"  id="bs_rate"/>
                </div>
                {/* Blood Cholesterol Module Elements */}
                <div className="scan__Box">
                    <label htmlFor='chol_rate'>Blood Cholesterol</label><br/>
                    <input type="text"  id="chol_rate"/>
                </div>
                {/* Use to get Information about the modules */}
                <input hidden id='check_basic' readOnly/>
                <input hidden id='check_bp' readOnly/>
                <input hidden id='check_bs' readOnly/>
                <input hidden id='check_bc' readOnly/>
                <div className="" hidden>
                <label htmlFor='diag_status' id='diag_label'>Status</label><br/>
                    <input id='diagnosis' type='text'/><input hidden type="text" id="diag_value"/>
                </div>
            </div >
            {/* <p>Note : The scanning results are to be used only as a reference to identify the bias and learning curve in the system and should not be considered a diagnostic tool during this study.</p> */}
            <div className='scan__BtnCont'>
                <button id="start_stop_btn" value="1">Start</button>
            </div>
            <p>Message : <span id='prog_dynamic'></span></p>
    </div>
  )

}

export default Scan
